import { Menu, Transition } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import React, { Fragment } from 'react'
import { LabelLink } from 'src/components/global/LabelLink'

/**
 * Primary UI component for user interaction
 */
export const IndustryAnalysisMenu: React.FC = () => {
  return (
    <Menu as="div" className={cn('relative inline-block h-full w-[150px] ml-[5px] mr-[20px] cursor-pointer text-left')}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={cn(
              'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
              { 'bg-white bg-opacity-5': open },
            )}
          >
            Industry Analysis
            <IonIcon
              className="text-white mr-0 !important"
              name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </Menu.Button>
          {open && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-30 overflow-hidden flex flex-row w-[275px] h-[13rem] rounded-[1.25rem] shadow-lg bg-white absolute"
                style={{ top: '60px' }}
              >
                <div className="absolute py-4 w-[275px] h-full overflow-y-auto">
                  <ul>
                    <li className="px-3 h-10 w-full">
                      <LabelLink
                        href="/blogs/"
                        className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                        label="Blogs"
                      />
                    </li>
                    <li className="px-3 h-10 w-full">
                      <LabelLink
                        href="/solution-guides/"
                        className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                        label="Solution Guides"
                      />
                    </li>
                    <li className="px-3 h-10 w-full">
                      <LabelLink
                        href="/know-how/"
                        className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                        label="Know-How"
                      />
                    </li>
                    <li className="px-3 h-12 w-full">
                      <LabelLink
                        href="/legaltech-resources-for-lawyers/"
                        className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                        label="Legaltech Resources for Lawyers"
                      />
                    </li>
                    {/* <li className="px-3 h-10 w-full">
                      <LabelLink
                        href={'/graveyard-consolidations'}
                        className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                        label="Graveyard & Consolidations"
                      />
                    </li> */}
                  </ul>
                </div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  )
}
