import { Menu, Transition } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Link from 'next/link'
import React, { Fragment } from 'react'
import { CompatibleLink } from 'src/components/CompatibleLink/CompatibleLink'
import { ITopicItemFragment } from 'src/graphql/generated/hooks'

interface TopicMenuProps {
  topicsData: ITopicItemFragment[]
  topicCurrentIdx: number
  handleSetTopicCurrentIdx: (index: number) => void
}

/**
 * Primary UI component for user interaction
 */
export const TopicsMenu: React.FC<TopicMenuProps> = ({ topicsData, topicCurrentIdx, handleSetTopicCurrentIdx }) => {
  return (
    <Menu as="div" className={cn('relative inline-block h-full w-24 cursor-pointer')}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={cn(
              'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
              { 'bg-white bg-opacity-5': open },
            )}
          >
            Topics
            <IonIcon
              className="text-white mr-0 !important"
              name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </Menu.Button>
          {open && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="relative left-[-304px] z-30 overflow-hidden flex flex-row w-[37.5rem] h-[30rem] rounded-[1.25rem] shadow-lg bg-white"
              >
                <div className="absolute left-[296px] border-l-[1px] py-4 w-[19rem] h-full overflow-y-auto">
                  <ul className="">
                    {topicsData.map((topic, idx) => {
                      return (
                        <li className="px-3 h-10 w-full" key={idx}>
                          <CompatibleLink
                            href={'/topics/' + topic.attributes?.slug}
                            className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                            onMouseOver={() => {
                              handleSetTopicCurrentIdx(idx)
                            }}
                          >
                            <IonIcon
                              className="mr-3"
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              name={topic.attributes?.iconName as any}
                            ></IonIcon>
                            {topic.attributes?.name}
                          </CompatibleLink>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="absolute flex flex-col py-4 w-[18.5rem] h-full top-0 left-0 border-l border-mist overflow-y-auto">
                  <p className="mx-6 text-textdark my-2 h-8 items-center border-b-2 border-mist text-[0.875rem] font-bold font-secondary text-left">
                    {topicsData[topicCurrentIdx]?.attributes?.name}
                  </p>
                  {topicsData[topicCurrentIdx]?.attributes?.subTopics?.data.map((submenu, subidx) => {
                    return (
                      <Menu.Item key={subidx}>
                        {({ active }) => (
                          <Link
                            href={
                              '/topics/' +
                              topicsData[topicCurrentIdx]?.attributes?.slug +
                              '/' +
                              submenu.attributes?.slug
                            }
                            className={cn(
                              { 'bg-gray-100': active },
                              'block px-6 mx-3 py-2 text-sm text-textdark font-secondary font-medium tracking-normal rounded-full hover:bg-turquoise hover:bg-opacity-10',
                            )}
                          >
                            {submenu.attributes?.name}
                          </Link>
                        )}
                      </Menu.Item>
                    )
                  })}
                  {topicsData[topicCurrentIdx] && (
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href={'/topics/' + topicsData[topicCurrentIdx]?.attributes?.slug}
                          className={cn(
                            { 'bg-gray-100': active },
                            'block px-6 mx-3 py-2 text-sm text-textdark font-secondary font-medium tracking-normal rounded-full hover:bg-turquoise hover:bg-opacity-10',
                          )}
                        >
                          View All
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  )
}
