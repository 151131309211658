import 'react-sliding-side-panel/lib/index.css'

import { Hidden } from '@mui/material'
import IonIcon from '@reacticons/ionicons'
import { Auth } from 'aws-amplify'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { Dispatch, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SlidingPanel from 'react-sliding-side-panel'
import { Slidingbar } from 'src/components/global/Slidingbar'
import { AccountMenu } from 'src/components/Header/AccountMenu'
import Logo from 'src/components/Header/Logo'
import { TopicsMenu } from 'src/components/Header/TopicsMenu'
import * as enVariables from 'src/config/config'
import { useGetTopicsForHeaderQuery } from 'src/graphql/generated/hooks'
import { removeAuth } from 'src/store/actions/auth'
import { AppState } from 'src/store/types'

import styles from './Header.module.scss'
import { IndustryAnalysisMenu } from './IndustryAnalysisMenu'

export interface MenuItem {
  title: string
  subMenuContained: boolean
}

export const Header: React.FC = () => {
  const authInfo = useSelector((state: AppState) => state.authInfo)
  const router = useRouter()
  const { data } = useGetTopicsForHeaderQuery({ fetchPolicy: 'cache-first' })
  const topicsData = data?.topics?.data || []
  const [topicCurrentIdx, setTopicCurrentIdx] = useState(0)
  const [isPaneOpen, setIsPaneOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const onClosePanel = () => {
    setIsPaneOpen(false)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch()

  const [isSSOUser, setIsSSOUser] = useState(false)

  useEffect(() => {
    setIsSSOUser(localStorage.getItem('sso') === 'true')
  }, [])

  useEffect(() => {
    const id = setInterval(() => {
      setIsLoading(true)
      clearInterval(id)
    }, 1000)
  })

  const MENU_OPTIONS: MenuItem[] = [
    { title: 'Topics', subMenuContained: true },
    { title: 'Industry Analysis', subMenuContained: true },
    { title: 'Events', subMenuContained: false },
    { title: 'Legaltech Jobs', subMenuContained: false },
    { title: 'About Us', subMenuContained: false },
    { title: 'Sign In', subMenuContained: false },
    { title: 'Sign Up', subMenuContained: false },
  ]

  const MENU_OPTIONS_SIGNIN: MenuItem[] = [
    { title: 'Topics', subMenuContained: true },
    { title: 'Industry Analysis', subMenuContained: true },
    { title: 'Events', subMenuContained: false },
    { title: 'Legaltech Jobs', subMenuContained: false },
    { title: 'About Us', subMenuContained: false },
    { title: 'My Account', subMenuContained: true },
  ]

  const handleSelectMenu = (title: string) => {
    if (title === 'Events') {
      setIsPaneOpen(false)
      router.push({ pathname: '/search', query: { type: 'upcoming' } })
    } else if (title === 'About Us') {
      setIsPaneOpen(false)
      router.push({ pathname: '/about' })
    }
  }

  const signOut = async () => {
    if (!isSSOUser) {
      await Auth.signOut()
    } else {
      removeAuth()
      localStorage.clear()
      router.push('/')
    }
    dispatch(removeAuth())
  }

  return (
    <div className={cn('w-full flex flex-row justify-center px-5 sm:px-0 bg-navy h-[3.1875rem]')}>
      {isLoading && (
        <div className="!max-w-[1100px] w-full h-full flex flex-row items-center justify-between">
          <Link href="/" passHref={true}>
            <Logo isPremium={authInfo.isPremium} isVendor={authInfo.isVendor} className="w-[12.5rem] h-[3.1875rem]" />
          </Link>

          <Hidden mdUp>
            <div className="flex flex-row ml-auto h-full">
              <IonIcon
                className="!h-[30px] !w-[30px] mt-[0.625rem] text-white !important"
                name="menu"
                onClick={() => {
                  setIsPaneOpen(true)
                }}
              />
              <div style={{ backgroundColor: '#011d58' }}>
                <SlidingPanel type={'right'} isOpen={isPaneOpen} size={90}>
                  <Slidingbar
                    topics={topicsData}
                    options={authInfo.name === '' ? MENU_OPTIONS : MENU_OPTIONS_SIGNIN}
                    onSelectMenu={handleSelectMenu}
                    onClosePanel={onClosePanel}
                    onSignout={signOut}
                  />
                </SlidingPanel>
              </div>
            </div>
          </Hidden>
          <Hidden mdDown>
            <div className="flex flex-row items-center h-full gap-1">
              <TopicsMenu
                topicsData={topicsData}
                topicCurrentIdx={topicCurrentIdx}
                handleSetTopicCurrentIdx={setTopicCurrentIdx}
              />

              <IndustryAnalysisMenu />

              <Link href="/events" className={cn(styles.menuItem, 'w-[70px]')}>
                Events
              </Link>

              <Link
                href={enVariables.JOB_URL}
                passHref
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.menuItem, 'w-[130px]')}
              >
                Legal<span className="text-turquoise">tech</span>&nbsp;Jobs
              </Link>

              <Link href="/about" className={cn(styles.menuItem, 'w-[5.4rem]')}>
                About Us
              </Link>

              <div style={{ borderLeft: '1px solid #fff', height: '30px', width: '5px' }}></div>

              {authInfo.name === '' || !authInfo ? (
                <div className="flex flex-row items-center">
                  <Link href="/signin" className={cn(styles.menuItem, 'w-[70px] ml-[15px]')}>
                    Sign In
                  </Link>
                  <Link
                    href="/pricing"
                    className="flex flex-row items-center h-[40px] text-white font-bold font-secondary text-[14px] bold text-center rounded-[22px] bg-navybright px-[12px]"
                  >
                    Sign Up
                  </Link>
                </div>
              ) : (
                <div className="flex flex-row items-center relative">
                  <p className={cn(styles.accountName, 'ml-[20px]')}>{authInfo.name?.slice(0, 1).toUpperCase()}</p>
                  <AccountMenu handleSignout={signOut} isPremium={authInfo.isPremium} isVendor={authInfo.isVendor} />
                </div>
              )}
            </div>
          </Hidden>
        </div>
      )}
    </div>
  )
}
