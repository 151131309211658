import cn from 'classnames'
import React from 'react'
import { CompatibleLink } from 'src/components/CompatibleLink/CompatibleLink'

interface LabelLinkProps {
  label: string
  href: string
  className?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

/**
 * Primary UI component for user interaction
 */
export const LabelLink: React.FC<LabelLinkProps> = ({ label, href, className = '', onClick }) => {
  return (
    <CompatibleLink href={href} className={cn(className, 'cursor-pointer')} onClick={onClick}>
      {label}
    </CompatibleLink>
  )
}
